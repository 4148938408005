import * as React from 'react'
import * as cookie from '@owl-nest/cookie-helper'

export function useHappening(
  key: string,
  startDate: string,
  endDate: string,
  cookieMinutesExpiry = 0,
): { isActive: boolean; markAsSeen: () => void } {
  const [isActive, setIsActive] = React.useState(false)

  React.useEffect(() => {
    if (checkEventHappening(startDate, endDate)) {
      const eventCookie = cookie.getCookie(key)

      if (!eventCookie) {
        setIsActive(true)
      }
    }
  }, [key, startDate, endDate])

  return {
    isActive,
    markAsSeen: () => {
      let cookieExpiryDate

      if (cookieMinutesExpiry) {
        cookieExpiryDate = new Date()
        cookieExpiryDate.setMinutes(cookieExpiryDate.getMinutes() + cookieMinutesExpiry)
      } else cookieExpiryDate = new Date(endDate)

      cookie.setCookie(key, 'true', {
        expires: cookieExpiryDate.toUTCString(),
      })
    },
  }
}

function checkEventHappening(startDate: string, endDate: string) {
  const clientDate = new Date()
  const start = new Date(startDate)
  const end = new Date(endDate)

  return clientDate >= start && clientDate <= end
}
