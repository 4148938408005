import styled, { css } from 'styled-components'

import * as color from '../constants/colors'
import * as loader from './loader'
import * as breakpoint from '../constants/breakpoints'
import * as icons from '../svg/icons'

export type ButtonProps = {
  autoSized?: boolean
  color?: 'grey' | 'green' | 'white'
  disabled?: boolean
  $loading?: boolean
  outlined?: boolean
  small?: boolean
}

export const UnstyledButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  text-align: left;

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`

export const Text = styled.span`
  text-align: center;
  display: flex;
  align-items: center;
  margin-top: -0.15em;
  height: 100%;
`

export const _smallStyles = css`
  padding: 12px 20px 14px;
  min-height: 50px;

  ${Text} {
    font-size: 14px;
    line-height: 14px;
  }

  ${icons.Icon} {
    width: 18px;
    height: 18px;
  }

  > *:not(${loader.Svg}) + * {
    margin-left: 10px;
  }
`

export const _bigStyles = css`
  padding: 16px 30px 20px;
  min-height: 60px;

  @media screen and (${breakpoint.MOBILE_L}) {
    font-size: 20px;
    ${Text} {
      transform: translateY(1px);
    }

    ${icons.Icon} {
      width: 24px;
      height: 24px;
    }
  }

  > *:not(${loader.Svg}) + * {
    margin-left: 15px;
  }
`

const buttonColors = {
  grey: {
    base: color.SAND_1,
    hover: color.GRAY_3,
    outlined: color.GRAY_3,
  },
  white: {
    base: color.GRAY_3,
    hover: color.SAND_1,
    outlined: color.WHITE,
  },
  green: {
    base: color.WHITE,
    hover: color.PINE_GREEN_1,
    outlined: color.PINE_GREEN_1,
  },
}

const buttonBaseStyles = css<ButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  line-height: 1;
  border-radius: 12px;
  transition: background 0.2s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  opacity: ${(props) => (props.disabled ? 0.35 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'initial')};

  ${icons.Icon} {
    flex-shrink: 0;
  }

  &:focus {
    outline: none;
  }

  ${({ autoSized }) =>
    autoSized
      ? css`
          @media not screen and (${breakpoint.MOBILE_L}) {
            width: 100%;
          }
          @media screen and (${breakpoint.MOBILE_L}) {
            min-width: 300px;
          }
        `
      : null}

  ${({ small }) => (small ? _smallStyles : _bigStyles)}
    ${({ outlined, color = 'grey' }) =>
    outlined
      ? css`
          color: ${buttonColors[color].outlined};
          background-color: transparent;
          box-shadow: inset 0 0 0 1.5px ${buttonColors[color].outlined};

          @media (hover: hover) {
            &:hover {
              color: ${buttonColors[color].base};
              background-color: ${buttonColors[color].outlined};
              box-shadow: inset 0 0 0 8px ${buttonColors[color].outlined};
            }
          }
        `
      : css`
          color: ${buttonColors[color].base};
          background: ${buttonColors[color].hover};
          box-shadow: inset 0 0 0 8px ${buttonColors[color].outlined};

          @media (hover: hover) {
            &:hover {
              color: ${buttonColors[color].hover};
              background: ${buttonColors[color].base};
              box-shadow: inset 0 0 0 1.5px ${buttonColors[color].outlined};
            }
          }
        `}
    ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;

      ${icons.Icon},
      ${Text} {
        opacity: 0;
      }
    `}
`

export const ButtonBase = styled.button<ButtonProps>`
  cursor: pointer;
  ${buttonBaseStyles};
`

export const ButtonAsLink = styled.a<ButtonProps>`
  ${buttonBaseStyles};
`

/**
 * Circular Button
 */

export type CircularButtonProps = ButtonProps & {
  borderless?: boolean
  small?: boolean
}

export const BaseCircularButton = styled(ButtonBase)`
  border-radius: 50%;
  font-size: 24px;
  height: max-content;
  min-height: 54px;
  padding: 0;
  width: 54px;
  min-width: initial;
  line-height: 54px;
  align-items: center;

  ${Text} {
    display: block;
  }
`

export const CircularButton = styled(BaseCircularButton)<CircularButtonProps>`
  ${({ borderless }) =>
    borderless &&
    css`
      box-shadow: none;
    `}
  ${({ small }) =>
    small &&
    css`
      font-size: 16px;
      min-height: 34px;
      width: 34px;
      line-height: 34px;
    `}
`
