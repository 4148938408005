import * as React from 'react'
import styled, { css } from 'styled-components'

import * as S from '../styles'
import { LazyImage } from './LazyImage'
import * as color from '../constants/colors'

export type PictureProps = S.picture.PictureProps &
  React.ImgHTMLAttributes<HTMLImageElement> & {
    sizeof?: string
    // When hasZoom is true, lazy doesn't work because the image is set as a background for the zoom feature
    hasZoom?: boolean
    nativeLazyLoad?: boolean
  }

function PictureComponent({
  className,
  sizing = 'cover',
  sizeof,
  src,
  srcSet,
  loading = 'lazy',
  nativeLazyLoad = false,
  hasZoom,
  ...imageProps
}: PictureProps): React.ReactElement<PictureProps> {
  const $zoomer = React.useRef<HTMLDivElement>(null)
  const [zoomIsActive, setZoomIsActive] = React.useState(false)
  return (
    <S.picture.Picture className={className} sizing={sizing} src={src}>
      {hasZoom && (
        <Zoomer ref={$zoomer} src={src ?? ''} onMouseMove={zoom} onMouseOut={dezoom} zoomIsActive={zoomIsActive} />
      )}
      {loading === 'lazy' ? (
        <LazyImage nativeLazyLoad={nativeLazyLoad}>
          <S.picture.Image src={src} srcSet={srcSet} sizing={sizing} {...imageProps} />
        </LazyImage>
      ) : (
        <S.picture.Image src={src} srcSet={srcSet} sizing={sizing} {...imageProps} />
      )}
      {sizeof && <Sizer src={sizeof} srcSet={srcSet} sizing={sizing} {...imageProps} />}
    </S.picture.Picture>
  )

  function zoom(event: React.MouseEvent<HTMLImageElement>) {
    if (event === undefined || $zoomer.current === null) {
      return null
    }
    !zoomIsActive && setZoomIsActive(true)
    const zoomer = $zoomer.current
    const offsetX = event.nativeEvent.offsetX
    const offsetY = event.nativeEvent.offsetY
    const x = (offsetX / zoomer.offsetWidth) * 100
    const y = (offsetY / zoomer.offsetHeight) * 100

    zoomer.style.backgroundPosition = x + '% ' + y + '%'
  }

  function dezoom(event: React.MouseEvent<HTMLImageElement>) {
    if (event === undefined || $zoomer.current === null) {
      return null
    }

    const zoomer = $zoomer.current
    zoomer.style.backgroundPosition = '0% 0%'
    setZoomIsActive(false)
  }
}

const Sizer = styled(S.picture.Image)`
  opacity: 0;
`

export const Picture = styled(PictureComponent)`
  ${({ sizeof }) => {
    if (sizeof !== undefined) {
      return css`
        position: relative;
        ${S.picture.Image}:not(${Sizer}) {
          position: absolute;
          top: 0;
          height: 100%;
        }
      `
    }
  }}
`

const Zoomer = styled.div<{ src: string; zoomIsActive: boolean }>`
  background-color: ${color.SAND_1};
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: 150%;
  cursor: zoom-in;
  height: 100%;
  opacity: ${({ zoomIsActive }) => (zoomIsActive ? 1 : 0)};
  position: absolute;
  width: 100%;
  z-index: 1;
`
