import styled from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as color from '../constants/colors'
import * as icons from '../svg/icons'
import * as zindex from '../constants/zindex'
import { hexToRgb } from '../utils/colors'

export const ModalBody = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 60px 20px 20px;
  position: absolute;
  top: 0;
  width: 100%;
`

export const ModalHeader = styled.header`
  height: 40px;
  position: sticky;
  z-index: 1;

  ${icons.Cross} {
    height: 22px;
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    width: 22px;

    &:hover {
      cursor: pointer;
    }
  }
`

export const ModalScrollableContainer = styled.div`
  max-height: 100%;
  overflow: auto;
  position: relative;
`

export const ModalWrapper = styled.div`
  color: ${color.GRAY_3};
  background-color: ${color.SAND_2};
  border-radius: 12px;
  height: 96%;
  margin: 10px;
  padding: 20px;
  position: relative;
  width: 100vw;

  @media screen and (${breakpoint.TABLET}) {
    height: 75vh;
    margin: 0;
    width: 50vw;
  }
`

export const OverlayBase = styled.div`
  align-items: center;
  background-color: ${hexToRgb(color.OVERLAY, 0.5)};
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: ${zindex.MODAL};

  @media not screen and (${breakpoint.TABLET}) {
    padding: 0px 20px;
  }
`

export type OverlayProps = {
  top?: number
  bottom?: number
  left?: number
  right?: number
}

export const Overlay = styled(OverlayBase)<OverlayProps>`
  top: ${({ top = 0 }) => top}px;
  left: ${({ left = 0 }) => left}px;
  bottom: ${({ bottom = 0 }) => bottom}px;
  right: ${({ right = 0 }) => right}px;
`

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
`
