type Crumb = { label: string; path: string }
type Entry = { label: string; path: string; crumbs: Crumb[] }

function normalize(path: string) {
  if (process.env.NEXT_PUBLIC_BOB_ROOT_URL) {
    return `${process.env.NEXT_PUBLIC_BOB_ROOT_URL}${path}`
  }
  return path
}

export function homepage(): Entry {
  const crumb = {
    label: 'Accueil',
    path: normalize('/'),
  }
  return { ...crumb, crumbs: [crumb] }
}

export function articles(params?: {
  environmentalPillar?: string
  searchableTag?: string
  socialPillar?: string
}): Entry {
  let path = '/magazine'

  if (params && Object.keys(params).length > 0) {
    path += `?${new URLSearchParams(params).toString()}`
  }

  const crumb = {
    label: 'Guides et actus',
    path: normalize(path),
  }

  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function article({ slug, title }: { slug: string; title: string }): Entry {
  const crumb = {
    label: title,
    path: normalize(`/magazine/${slug}`),
  }
  return {
    ...crumb,
    crumbs: [...articles().crumbs, crumb],
  }
}

export function brands(params?: {
  bobChart?: string
  environmentalPillar?: string
  searchableTag?: string
  socialPillar?: string
}): Entry {
  let path = '/les-marques'

  if (params && Object.keys(params).length > 0) {
    path += `?${new URLSearchParams(params).toString()}`
  }

  const crumb = {
    label: 'Les marques',
    path: normalize(path),
  }

  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function brand({ slug, name }: { slug: string; name: string }): Entry {
  const crumb = {
    label: name,
    path: normalize(`/les-marques/${slug}`),
  }
  return {
    ...crumb,
    crumbs: [...brands().crumbs, crumb],
  }
}

export function manifesto(): Entry {
  const crumb = {
    label: 'Charte',
    path: normalize('/charte'),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function newsletter(): Entry {
  const crumb = {
    label: 'Infolettre',
    path: normalize('/infolettre'),
  }
  return {
    ...crumb,
    crumbs: [],
  }
}

export function aboutPage({ slug, title }: { slug: string; title: string }): Entry {
  const crumb = {
    label: title,
    path: normalize(`/a-propos/${slug}`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function account(): Entry {
  const crumb = {
    label: 'Mon compte',
    path: normalize(`/mon-compte`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function orders(): Entry {
  const crumb = {
    label: 'Mes commandes',
    path: normalize(`/mon-compte/mes-commandes`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function profile(): Entry {
  const crumb = {
    label: 'Mon profil',
    path: normalize(`/mon-compte/mon-profil`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function reviews(): Entry {
  const crumb = {
    label: 'Mes avis',
    path: normalize(`/mon-compte/mes-avis`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function favorites(): Entry {
  const crumb = {
    label: 'Mes favoris',
    path: normalize(`/mon-compte/mes-favoris`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function order({ shopifyOrderNumber, vendor }: { shopifyOrderNumber: number; vendor: string }): Entry {
  const crumb = {
    label: 'Mon Compte',
    path: normalize(`/mon-compte/mes-commandes/${shopifyOrderNumber}-${vendor}`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function orderReview({ shopifyOrderNumber, vendor }: { shopifyOrderNumber: number; vendor: string }): Entry {
  const crumb = {
    label: 'Mon Compte',
    path: normalize(`/mon-compte/mes-commandes/${shopifyOrderNumber}-${vendor}/review`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function review({ variantId }: { variantId: number }): Entry {
  const crumb = {
    label: 'Mon Compte',
    path: normalize(`/account/reviews/${variantId}`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function orderReturn({ shopifyOrderNumber, vendor }: { shopifyOrderNumber: number; vendor: string }): Entry {
  const crumb = {
    label: 'Mon Compte',
    path: normalize(`/mon-compte/mes-commandes/${shopifyOrderNumber}-${vendor}/retour`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function orderReturnSummary({
  shopifyOrderNumber,
  vendor,
}: {
  shopifyOrderNumber: number
  vendor: string
}): Entry {
  const crumb = {
    label: 'Mon Compte',
    path: normalize(`/mon-compte/mes-commandes/${shopifyOrderNumber}-${vendor}/resume-du-retour`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function notFound(): Entry {
  const crumb = {
    label: '404',
    path: normalize(`/404`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function userReview(): Entry {
  const crumb = {
    label: 'Mon Compte',
    path: normalize(`/account/reviews/`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function tag({ name, slug }: { name: string; slug: string }): Entry {
  const crumb = {
    label: name,
    path: normalize(`/tags/${slug}`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}
