import * as React from 'react'
import styled from 'styled-components'

import * as ds from '@bob/design-system'

import * as hooks from '@owl-nest/hooks'

import { useToast, Toast } from './context'

export function ToastList(): React.ReactElement {
  const { toasts, dismiss } = useToast()

  return (
    <List>
      {toasts.map((toast) => (
        <Li key={toast.id}>
          <ToastItem toast={toast} dismiss={dismiss} />
        </Li>
      ))}
    </List>
  )
}

const ToastItem = React.memo(function Toast({ toast, dismiss }: { toast: Toast; dismiss: (id: number) => void }) {
  const [isHover, setIsHover] = React.useState(false)

  hooks.useInterval(
    () => {
      dismiss(toast.id)
    },
    isHover ? null : 5000,
  )

  return (
    <ds.Toast
      closable={toast.closable}
      onClose={() => dismiss(toast.id)}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      status={toast.status}
    >
      {toast.children}
    </ds.Toast>
  )
})

const List = styled.ul`
  z-index: ${ds.zindex.TOAST};
  position: fixed;
  bottom: 16px;
  right: 12.5px;
  left: 12.5px;
  width: auto;

  @media screen and (${ds.breakpoint.TABLET}) {
    width: 400px;
    left: auto;
  }
`

const Li = styled.li`
  margin: 10px 0px;

  &:first-child {
    margin: 0px 0px 10px;
  }
  &:last-child {
    margin: 10px 0px 0px;
  }
`
