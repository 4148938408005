import * as React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import { styles as S } from '@bob/design-system'

type NavLinkComponentProps = {
  href: string
  children: React.ReactNode
}

function NavLinkComponent({ href, children }: NavLinkComponentProps): React.ReactElement {
  return (
    <Link prefetch={false} href={href} passHref legacyBehavior>
      <LinkStyled>{children}</LinkStyled>
    </Link>
  )
}
export const NavLink = styled(NavLinkComponent)``

export const LinkStyled = styled(S.link.Base)`
  font-size: 22px;
  text-decoration: none;
  line-height: 1.25;
`
