import * as React from 'react'

type PageViewManager = {
  whitelistedParams: string[]
  url: string
  pageview: boolean
}

const pageViewContext = React.createContext<undefined | PageViewManager>(undefined)

type PageViewProviderProps = {
  manager: PageViewManager
  children: React.ReactNode
}

declare global {
  interface Plausible {
    (event: string, options: { u: string }): void
    (event: string, options?: { props?: Record<string, string>; callback?: () => void }): void
    q?: any[]
  }

  interface Window {
    plausible?: Plausible
  }
}

export function getPageViewManager(whitelistedParams: string[] = []): PageViewManager {
  return {
    url: getPlausibleReadyUrl(typeof window === 'undefined' ? undefined : window.location.href, whitelistedParams),
    whitelistedParams,
    pageview: false,
  }
}

export function PageViewProvider({
  manager,
  children,
}: PageViewProviderProps): React.ReactElement<PageViewProviderProps> {
  return <pageViewContext.Provider value={manager}>{children}</pageViewContext.Provider>
}

/**
 * On each render, compute a tracking url from the current url and whitelisted
 * query params.
 *
 * If this url has changed since the previous pageview, the hook
 * triggers a new pageview.
 *
 * If a child uses the hook with a different set of whitelisted query params
 * giving a different tracking url, the child takes precedence and triggers a
 * pageview with its url, and the parent component does nothing.
 *
 * Whitelisted query params are inherited from parent components (children can only
 * add whitelisted query params, but not remove them).
 */
export function usePageView(whitelistedParams: string[] = []): void {
  const manager = React.useContext(pageViewContext)

  if (manager === undefined) {
    throw Error('')
  }

  manager.whitelistedParams = [...new Set([...manager.whitelistedParams, ...whitelistedParams])]

  const url = getPlausibleReadyUrl(
    typeof window === 'undefined' ? undefined : window.location.href,
    manager.whitelistedParams,
  )

  if (manager.url !== url) {
    manager.url = url
    manager.pageview = false
  }

  React.useEffect(() => {
    if (manager.url === url && !manager.pageview && typeof window !== 'undefined') {
      manager.pageview = true
      if (window.plausible !== undefined) {
        window.plausible('pageview', { u: manager.url })
      } else {
        console.log('pageview', { u: manager.url })
      }
    }
  }, [manager.url, manager.pageview])
}

const PRESERVED_QUERY_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

export function getPlausibleReadyUrl(href: string | undefined, whitelistedParams: string[]): string {
  if (href === undefined) {
    return ''
  }
  const url = new URL(href)
  const queryParams = new URLSearchParams(url.searchParams)
  url.search = ''
  const urlWithoutQueryParams = url.toString()

  const sortedWhitelistedParams = [...whitelistedParams].sort()

  const plausibleReadyUrlFragments = [urlWithoutQueryParams]
  for (const param of sortedWhitelistedParams) {
    const value = queryParams.get(param)
    if (value !== null) {
      plausibleReadyUrlFragments.push(param, value)
    }
  }

  const search = new URLSearchParams()
  for (const param of PRESERVED_QUERY_PARAMS) {
    const value = queryParams.get(param)
    if (value !== null) {
      search.set(param, value)
    }
  }

  const searchString = search.toString()
  return plausibleReadyUrlFragments.join('/') + (searchString.length === 0 ? '' : `?${searchString}`)
}
