import * as React from 'react'
import dynamic from 'next/dynamic'
import Link, { type LinkProps } from 'next/link'
import styled, { ThemeProvider } from 'styled-components'
import PlausibleProvider from 'next-plausible'

import { useHappening } from '@owl-nest/hooks'
import { PageViewProvider, getPageViewManager, usePageView } from '@boutique/shadow'
import { useUTMCatcher } from '@boutique/utm'
import type { BobWeekModalProps } from '@bob/components'
import * as ds from '@bob/design-system'
import { ToastsProvider } from '@bob/toasts'

import { AppFooter } from './Footer'
import { AppHeader } from './Header'
import { NotificationBanner } from './NotificationBanner'

const BobWeekModal = dynamic<BobWeekModalProps>(() => import('@bob/components').then((mod) => mod.BobWeekModal))

type LayoutProps = {
  children: React.ReactNode
}

type GenericLinkProps = Omit<LinkProps, 'href'> & { href?: LinkProps['href'] }

function GenericLink({ href, children, ...props }: React.PropsWithChildren<GenericLinkProps>): React.ReactElement {
  if (href !== undefined) {
    return (
      <Link {...props} href={href} prefetch={false}>
        {children}
      </Link>
    )
  }
  return <>{children}</>
}

export function InnerLayout({ children }: LayoutProps): React.ReactElement<unknown> {
  const { isActive, markAsSeen } = useHappening('bob-week-2024', '04/02/2024 00:00', '04/07/2024 23:59')

  useUTMCatcher()
  usePageView()

  return (
    <App>
      <PlausibleProvider manualPageviews domain={process.env.NEXT_PUBLIC_SITE_DOMAIN!}>
        <ds.GlobalStyleDesignSystem />
        <ds.LinkWrapperProvider value={GenericLink}>
          <ThemeProvider theme={ds.theme}>
            <ToastsProvider>
              <AppHeader />
              <NotificationBanner />
              {isActive && <BobWeekModal onClose={() => markAsSeen()} />}
              <Main>{children}</Main>
              <AppFooter />
            </ToastsProvider>
          </ThemeProvider>
        </ds.LinkWrapperProvider>
      </PlausibleProvider>
    </App>
  )
}

const pageViewManager = getPageViewManager()

export function Layout(props: LayoutProps): React.ReactElement {
  return (
    <PageViewProvider manager={pageViewManager}>
      <InnerLayout {...props} />
    </PageViewProvider>
  )
}

export const App = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Main = styled.main`
  flex-grow: 1;
`
