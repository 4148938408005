import * as React from 'react'

export enum Step {
  BEFORE = 'before',
  DURING = 'during',
  AFTER = 'after',
}

export type UseTransition = {
  step: Step
  unmounted: boolean
  onTransitionEnd: () => void
}

export function useTransition(visible: boolean): UseTransition {
  const [shouldRender, setRender] = React.useState(false)
  const isHookMountedRef = React.useRef(false)

  React.useEffect(() => {
    isHookMountedRef.current = true

    return () => {
      isHookMountedRef.current = false
    }
  }, [])

  React.useEffect(() => {
    if (visible) {
      // on utilise un setTimeout pour garantir que le setRender déclenchera un rerender différent du rerender à l'origine du useEffect (éviter le batch des renders)
      // ceci permet de garantir qu' on aura un render avec un step 'before' distinct du render immédiat suivant en step 'during'
      setTimeout(() => {
        if (isHookMountedRef.current) {
          setRender(true)
        }
      }, 0)
    }
  }, [visible])

  const onTransitionEnd = (): void => {
    if (!visible) setRender(false)
  }

  let step: Step
  if (visible && !shouldRender) {
    step = Step.BEFORE
  } else if (!visible && shouldRender) {
    step = Step.AFTER
  } else {
    step = Step.DURING
  }

  const unmounted = !visible && !shouldRender

  return { step, unmounted, onTransitionEnd }
}
