import * as React from 'react'
import styled from 'styled-components'

import { useScrollLock } from '../hooks'
import * as S from '../styles'

export type OverlayProps = S.modal.OverlayProps & React.HTMLAttributes<HTMLDivElement>

// eslint-disable-next-line react/display-name
const OverlayComponent = React.forwardRef<HTMLDivElement, OverlayProps>((props, ref) => {
  useScrollLock(true)
  return <S.modal.Overlay ref={ref} {...props} />
})

export const Overlay = styled(OverlayComponent)``
