import * as React from 'react'
import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-twitter-def'

FullTwitter.filename = SYMBOL_ID
export function FullTwitter(props: icon.SpecificIconProps): React.ReactElement {
  return (
    <icon.Icon {...props} name="twitter" viewBox="0 0 26 26">
      <defs>
        <g id={SYMBOL_ID}>
          <path d="M15.4735 11.0092L25.1526 0H22.859L14.4546 9.55916L7.74211 0H0L10.1507 14.4551L0 26H2.29376L11.169 15.9052L18.2579 26H26L15.473 11.0092H15.4735ZM12.3319 14.5825L11.3034 13.1431L3.12024 1.68958H6.64332L13.2473 10.9329L14.2757 12.3723L22.8601 24.3873H19.337L12.3319 14.583V14.5825Z" />
        </g>
      </defs>
      <use href={`#${SYMBOL_ID}`} />
    </icon.Icon>
  )
}

export const Twitter = styled(function Twitter(props: icon.SpecificIconProps): React.ReactElement {
  const href = useSvgHref(SYMBOL_ID)
  return (
    <icon.Icon {...props} name="twitter" viewBox="0 0 26 26">
      <use href={href} />
    </icon.Icon>
  )
})`
  transform: scale(0.9);
  transform-origin: center;
`
