import * as contentful from '@boutique/contentful'
import * as entity from '@boutique/entities'

import { paginate } from './utils.ts'

type SlimDenormalized = {
  brands: Record<string, entity.brand.SlimColdBrand>
  brandCategories: Record<string, entity.category.SlimColdCategory>
}

export function slimDenormalize(
  collector: SlimCollector,
  cold: {
    tags: Record<string, entity.tag.ColdTag>
  },
): SlimDenormalized {
  const denormalized: SlimDenormalized = { brands: {}, brandCategories: collector.brandCategories }

  for (const brand of Object.values(collector.brands)) {
    denormalized.brands[brand.slug] = {
      ...brand,
      brandCategories: brand.brandCategories.map((slug) => collector.brandCategories[slug]),
      pillars: {
        social: brand.pillars.social.map((slug) => cold.tags[slug] as any),
        environmental: brand.pillars.environmental.map((slug) => cold.tags[slug] as any),
      },
    }
  }

  return denormalized
}

type Denormalized = {
  brands: Record<string, entity.brand.ColdBrand>
  brandCategories: Record<string, entity.category.ColdCategory>
}

export function denormalize(
  collector: Collector,
  cold: {
    tags: Record<string, entity.tag.ColdTag>
  },
): Denormalized {
  const denormalized: Denormalized = { brands: {}, brandCategories: collector.brandCategories }

  for (const brand of Object.values(collector.brands)) {
    denormalized.brands[brand.slug] = {
      ...brand,
      brandCategories: brand.brandCategories.map((slug) => collector.brandCategories[slug]),
      moments: brand.moments.map((slug) => cold.tags[slug] as any),
      pillars: {
        social: brand.pillars.social.map((slug) => cold.tags[slug] as any),
        environmental: brand.pillars.environmental.map((slug) => cold.tags[slug] as any),
      },
      tags: brand.tags.map((slug) => cold.tags[slug]),
    }
  }

  return denormalized
}

export type SlimCollector = {
  brands: Record<
    string,
    Omit<entity.brand.SlimColdBrand, 'brandCategories' | 'pillars'> & {
      brandCategories: string[]
      pillars: { environmental: string[]; social: string[] }
    }
  >
  brandCategories: Record<string, entity.category.SlimColdCategory>
}

export function slim(collector: Collector): SlimCollector {
  return {
    brands: Object.entries(collector.brands).reduce(
      (brands, [slug, coldBrand]) => {
        brands[slug] = {
          brandCategories: coldBrand.brandCategories,
          coverImage: coldBrand.coverImage,
          descriptionShort: coldBrand.descriptionShort,
          faq: coldBrand.faq,
          identifier: coldBrand.identifier,
          logo: coldBrand.logo,
          pillars: coldBrand.pillars,
          productReturnContactUrl: coldBrand.productReturnContactUrl,
          rationale: coldBrand.rationale,
          returnInstructions: coldBrand.returnInstructions,
          signatory: coldBrand.signatory,
          sizeGuide: coldBrand.sizeGuide,
          slug: coldBrand.slug,
          name: coldBrand.name,
        }
        return brands
      },
      {} as SlimCollector['brands'],
    ),
    brandCategories: Object.entries(collector.brandCategories).reduce(
      (brandCategories, [slug, coldCategory]) => {
        brandCategories[slug] = entity.category.slimColdCategory(coldCategory)
        return brandCategories
      },
      {} as SlimCollector['brandCategories'],
    ),
  }
}

export type Collector = {
  brands: Record<
    string,
    Omit<entity.brand.ColdBrand, 'brandCategories' | 'pillars' | 'moments' | 'tags'> & {
      brandCategories: string[]
      pillars: { environmental: string[]; social: string[] }
      tags: string[]
      moments: string[]
    }
  >
  brandCategories: Record<string, entity.category.ColdCategory>
}

export async function collect(
  locale: string,
  cold: {
    tags: Record<string, entity.tag.ColdTag>
    taxonomies: Record<string, entity.taxonomy.ColdTaxonomy>
  },
): Promise<Collector> {
  const collector: Collector = { brands: {}, brandCategories: {} }

  const categories = await getBrandCategories(locale)
  for (const category of categories) {
    collector.brandCategories[category.slug] = {
      altName: category.altName,
      descriptionShort: category.descriptionShort,
      name: category.name,
      order: category.order,
      slug: category.slug,
    }
  }

  const brands = await getBrands(locale, { ...cold, brandCategories: collector.brandCategories })
  for (const brand of brands) {
    collector.brands[brand.slug] = {
      ...brand,
      brandCategories: brand.brandCategories.map((category) => category.slug),
      pillars: {
        environmental: brand.pillars.environmental.map((pillar) => pillar.slug),
        social: brand.pillars.social.map((pillar) => pillar.slug),
      },
      tags: brand.tags.map((tag) => tag.slug),
      moments: brand.moments.map((moment) => moment.slug),
    }
    brand.tags = brand.tags.filter((tag) => cold.tags[tag.slug])
    brand.brandCategories = brand.brandCategories.filter((category) => collector.brandCategories[category.slug])
  }

  return collector
}

function getBrandCategories(locale: string) {
  return paginate({
    getPage: contentful.brandCategory.getColdCollection,
    items: (page) => page.brandCategoryCollection.items,
    total: (page) => page.brandCategoryCollection.total,
    locale,
    batch: 50,
  })
}

function getBrands(
  locale: string,
  cold: {
    brandCategories: Record<string, entity.category.ColdCategory>
    tags: Record<string, entity.tag.ColdTag>
    taxonomies: Record<string, entity.taxonomy.ColdTaxonomy>
  },
): Promise<entity.brand.ColdBrand[]> {
  return paginate<entity.brand.ColdBrand, { total: number; items: entity.brand.ColdBrand[] }>({
    getPage: async (input) => {
      const { brandCollection } = await contentful.brand.getColdCollection(input)

      return {
        total: brandCollection.total,
        items: brandCollection.items.map((brand) => entity.brand.coldBrand(brand, cold)),
      }
    },
    items: (result) => result.items,
    total: (result) => result.total,
    locale,
    batch: 8,
  })
}
