import * as React from 'react'
import type * as next from 'next/app'
import { DefaultSeo } from 'next-seo'
import { createGlobalStyle } from 'styled-components'

import { SuspendedLocationInitializer } from '@owl-nest/shared-next'
import * as hooks from '@owl-nest/hooks'

import { SITE_NAME, FACEBOOK_APP_ID, SITE_TWITTER_HANDLE } from '@bob/config'
import { SvgDirProvider } from '@bob/design-system'
import { Layout } from '@bob/layout'

//import { BobMondayModal } from '@bob/components'
import { BobXmasGameModal } from '@bob/components'
//import { BobWeekModal } from '@bob/components'
//import { BobBirthdayModal } from '@bob/components'

const defaultSeo = {
  defaultTitle: SITE_NAME,
  openGraph: {
    locale: 'fr_FR',
    site_name: SITE_NAME,
    type: 'website',
  },
  facebook: { appId: FACEBOOK_APP_ID },
  twitter: {
    site: SITE_TWITTER_HANDLE,
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      type: 'image/png',
      href: 'https://d2homsd77vx6d2.cloudfront.net/files/169/271/210/7/f1739fe6d7082f0a5cd3a5e7eec2818e.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '48x48',
      href: 'https://d2homsd77vx6d2.cloudfront.net/files/169/271/210/7/f1739fe6d7082f0a5cd3a5e7eec2818e.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: 'https://d2homsd77vx6d2.cloudfront.net/files/169/271/222/7/7f5dcb812f04b2c18ec5ea4ffbf5ab88.png',
    },
    {
      rel: 'apple-touch-icon',
      href: `${process.env.ASSET_PREFIX}/assets/bob_apple-touch-icon_114.png`,
      sizes: '114x114',
    },
    {
      rel: 'apple-touch-icon-precomposed',
      href: `${process.env.ASSET_PREFIX}/assets/bob_apple-touch-icon_114.png`,
      sizes: '114x114',
    },
  ],
}

export default function App(appProps: next.AppProps): React.ReactElement {
  const { isActive, markAsSeen } = hooks.useHappening('bob-xmas-game', '11/08/2023 00:00', '12/11/2023 23:59')
  return (
    <SvgDirProvider value={'/assets/svg/'}>
      <GlobalStyle />
      <DefaultSeo {...defaultSeo} />
      {isActive && (
        <BobXmasGameModal
          onClose={() => {
            markAsSeen()
          }}
        />
      )}

      <SuspendedLocationInitializer router={appProps.router as hooks.NextRouter}>
        <Layout>
          <appProps.Component {...appProps.pageProps} />
        </Layout>
      </SuspendedLocationInitializer>
    </SvgDirProvider>
  )
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: sofia-pro,sans-serif;
    margin: 0;
    padding-top: 0;
    text-rendering: optimizeLegibility;
  }
`
