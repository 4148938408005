import styled, { css } from 'styled-components'

import * as ds from '@bob/design-system'

export const Overlay = styled.div<{ mobileOnly?: boolean; visible: boolean }>`
  background-color: ${ds.utils.color.hexToRgb(ds.color.OVERLAY, 0.5)};
  height: 100%;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: fixed;
  top: ${ds.sizes.HEADER_MOBILE_INTERNAL_HEIGHT};
  transition-duration: 600ms;
  transition-property: opacity, visibility;
  transition-timing-function: ease-in-out;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: 100%;
  z-index: -1;

  @media screen and (${ds.breakpoint.TABLET}) {
    top: ${ds.sizes.HEADER_DESKTOP_INTERNAL_HEIGHT};

    ${({ mobileOnly = false }) => {
      if (mobileOnly) {
        return css`
          display: none;
        `
      }
    }}
  }
`
