import * as React from 'react'

import { newsletter } from '@bob/services'
import { useToast } from '@bob/toasts'

type State =
  | { status: 'pristine' }
  | { status: 'pending' }
  | { status: 'success' }
  | { status: 'failure'; message: string }

export function useNewsletterRegistration(list: string): [State, (email: string) => Promise<void>] {
  const [state, setState] = React.useState<State>({ status: 'pristine' })
  const toast = useToast()
  return [state, register]

  async function register(email: string) {
    setState({ status: 'pending' })

    // Force at least 200ms between "pending" and "success"/"failure"
    // to avoid flash of "loading" state
    const [result] = await Promise.all([newsletter.register(email, list), new Promise((res) => setTimeout(res, 200))])
    if (result.success) {
      setState({ status: 'success' })
      toast.dispatch({
        status: 'success',
        id: Date.now(),
        children: 'Vous êtes maintenant inscrit·e à la newsletter',
      })
    } else {
      setState({ status: 'failure', message: result.message })
      toast.dispatch({
        status: 'error',
        id: Date.now(),
        children: result.message,
      })
    }
  }
}
