import * as React from 'react'
import Link from 'next/link'

import * as entities from '@boutique/entities'
import * as url from '@boutique/url'
import * as ds from '@bob/design-system'

type HyperlinkProps = { uri: string } & React.AnchorHTMLAttributes<HTMLAnchorElement>

export function Hyperlink({ children, uri }: HyperlinkProps): React.ReactElement {
  const url = new URL(uri)

  const isJavascript = url.protocol.includes('javascript')

  const [first, second] = url.hostname.split('.')
  const isRootDomain = first === 'bienoubien' || first === 'www' ? second === 'bienoubien' : false

  if (isRootDomain) {
    return (
      <Link prefetch={false} href={getInternalHref(url)} passHref legacyBehavior>
        <ds.styles.wysiwyg.Hyperlink>{children}</ds.styles.wysiwyg.Hyperlink>
      </Link>
    )
  }

  // Required to launch Axeptio widget
  if (isJavascript) {
    return <ds.styles.wysiwyg.Hyperlink href={uri}>{children}</ds.styles.wysiwyg.Hyperlink>
  }

  return (
    <ds.styles.wysiwyg.Hyperlink href={uri} rel="noopener noreferer external" target="_blank">
      {children}
    </ds.styles.wysiwyg.Hyperlink>
  )
}

const rewrites: ((url: URL) => string | undefined)[] = [
  (linkUrl: URL) => {
    if (linkUrl.pathname.startsWith('/les-produits')) {
      return url.products({
        query: entities.search.product.fromParsedUrlQuery(Object.fromEntries(linkUrl.searchParams.entries())),
      }).path
    }
  },
  (linkUrl: URL) => {
    const match = linkUrl.pathname.match(/^\/magasin\/(.*)-(\d+)$/)
    if (match) {
      return url.product({ handle: match[1], id: Number(match[2]), title: '' }).path
    }
  },
]

function getInternalHref(linkUrl: URL): string {
  for (const rewrite of rewrites) {
    const href = rewrite(linkUrl)
    if (href) {
      return href
    }
  }

  return linkUrl.pathname + linkUrl.search + linkUrl.hash
}
