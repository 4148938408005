import styled, { css } from 'styled-components'

import * as color from '../constants/colors'

import * as copy from './copy'
import * as icons from '../svg/icons'

import { hexToRgb } from '../utils/colors'

export type ToastProps = {
  status?: 'classic' | 'info' | 'success' | 'alert' | 'error'
  noShadow?: boolean
}

const BaseToast = styled.div<ToastProps>`
  ${({ noShadow }) => {
    if (!noShadow) {
      return css`
        box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
      `
    }
  }}

  border-radius: 12px;

  align-items: center;
  display: flex;

  padding: 14px 20px;

  ${icons.Icon} {
    flex: none;
    margin-right: 22px;
  }
  ${icons.Cross} {
    align-self: flex-start;
    cursor: pointer;
    margin-right: 0px;
    margin-left: 0px;
  }
`

export const Message = styled(copy.Text1)`
  margin: 0;
  padding: 0;
  flex-grow: 1;
`

export const Toast = styled(BaseToast)<ToastProps>`
  ${({ status }) => {
    if (status === 'classic' || status === 'info')
      return css`
        border-color: ${color.GRAY_3};
        color: ${color.GRAY_3};
        background-color: ${hexToRgb(color.SAND_2, 0.9)};
      `
    if (status === 'success')
      return css`
        border-color: ${color.LIGHT_GREEN};
        background-color: ${color.LIGHT_GREEN};
        color: ${color.WHITE};
      `
    if (status === 'alert')
      return css`
        border-color: ${color.PINK};
        background-color: ${color.PINK};
      `
    if (status === 'error')
      return css`
        border-color: ${color.ERROR};
        background-color: ${color.ERROR};
        color: ${color.WHITE};
      `
  }}
`
