import * as React from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import * as ds from '@bob/design-system'
import * as url from '@bob/url'

export function Search(): React.ReactElement {
  const router = useRouter()

  const [searchValue, setSearchValue] = React.useState('')

  return (
    <SearchInput
      onChange={(event) => {
        setSearchValue(event.target.value)
      }}
      value={searchValue}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          const targetUrl = searchValue === '' ? url.brands().path : url.brands({ searchableTag: searchValue }).path

          router.push(targetUrl, undefined, { shallow: true })

          setSearchValue('')

          event.currentTarget.blur()
        }
      }}
      placeholder="Rechercher une marque"
    />
  )
}

const SearchInput = styled(ds.SearchInput)`
  display: none;

  @media screen and (${ds.breakpoint.TABLET}) {
    display: initial;

    ${ds.styles.form.input.TextInput} {
      width: 210px;
    }
  }
`
