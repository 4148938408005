import * as React from 'react'

export type Toast = {
  children: string
  status: 'classic' | 'info' | 'success' | 'alert' | 'error'
  closable?: boolean
  id: number
}

export type ToastManager = {
  toasts: Toast[]
  dispatch: (toast: Toast) => void
  dismiss: (id: number) => void
}
export const toastContext = React.createContext<undefined | ToastManager>(undefined)

export function useToast(): ToastManager {
  const manager = React.useContext(toastContext)

  if (manager === undefined) {
    throw new Error('Missing provider')
  }

  return manager
}
