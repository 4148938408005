import { type SlimCold } from './client.ts'
import { type Cold } from './index.ts'

type AnyCold = Pick<Cold, 'taxonomies' | 'types'> | Pick<SlimCold, 'taxonomies' | 'types'>
type AnyTaxonomy = AnyCold['taxonomies'][string]

export type Tree = {
  id: string
  tags: string[]
  name: string
  parents: { name: string; id: string }[]
  type: AnyTaxonomy['type'] | '$$root$$'
  children: Tree[]
}

type Index = Record<string, number[]>

export function treeFrom(taxonomy: AnyTaxonomy, index: Index, cold: AnyCold, path: number[], parentTree?: Tree): Tree {
  const tree: Tree = {
    type: taxonomy.type,
    id: taxonomy.slug,
    tags: taxonomy.children.tags,
    name: taxonomy.name,
    parents: parentTree ? [...parentTree.parents, { name: parentTree.name, id: parentTree.id }] : [],
    children: [],
  }

  index[tree.id] = path

  const taxonomies = taxonomy.children.taxonomies
    .map((slug) => cold.taxonomies[slug])
    .sort((a, b) => {
      const taxonomyA = cold.taxonomies[a.slug]
      const taxonomyB = cold.taxonomies[b.slug]

      return (taxonomyA?.order ?? 0) - (taxonomyB?.order ?? 0)
    })

  let i = 0
  for (const taxonomy of taxonomies) {
    if (taxonomy) {
      if (taxonomy.type === 'sub-category-2') {
        for (const slug of taxonomy.children.taxonomies) {
          const taxonomy = cold.taxonomies[slug]
          if (taxonomy) {
            const childtree = treeFrom(cold.taxonomies[slug], index, cold, [...path, i++], tree)
            tree.children.push(childtree)
          }
        }
      } else {
        const childtree = treeFrom(taxonomy, index, cold, [...path, i++], tree)
        tree.children.push(childtree)
      }
    }
  }

  tree.children.sort((a, b) => {
    const taxonomyA = cold.taxonomies[a.id]
    const taxonomyB = cold.taxonomies[b.id]

    return (taxonomyA?.order ?? 0) - (taxonomyB?.order ?? 0)
  })

  return tree
}

export type Hierarchy = { tree: Tree; index: Index }

export function makeHierarchy(cold: AnyCold): Hierarchy {
  const trees: Tree[] = []
  const index = {}

  const categories = cold.types['category']
    .map((slug) => cold.taxonomies[slug])
    .sort((a, b) => {
      const taxonomyA = cold.taxonomies[a.slug]
      const taxonomyB = cold.taxonomies[b.slug]

      return (taxonomyA?.order ?? 0) - (taxonomyB?.order ?? 0)
    })

  let i = 0
  for (const category of categories) {
    trees.push(treeFrom(category, index, cold, [i++]))
  }

  return {
    tree: {
      id: '$$root$$',
      tags: [],
      name: '$$root$$',
      type: '$$root$$',
      parents: [],
      children: trees,
    },
    index,
  }
}
