import styled, { css } from 'styled-components'

import * as color from '../constants/colors'
import * as font from '../constants/fonts'

import * as icons from '../svg/icons'

export type LinkButtonProps = {
  disabled?: boolean
}

export const LinkText = styled.span`
  display: block;

  padding: 3px 0;
  height: 100%;
`

const buttonStyles = css`
  position: relative;
  display: inline-flex;
  color: ${color.GRAY_3};
  font-family: ${font.PRIMARY};
  text-align: left;
  overflow: hidden;
  &::before {
    display: block;
    content: '';
    height: 3px;
    background: ${color.GRAY_3};
    position: absolute;
    bottom: 0;
    left: -1px;
    right: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
  ${icons.ArrowRight} {
    border-bottom: 3px solid transparent;
    flex-shrink: 0;
    margin-left: 15px;
    margin-top: 4px;
    transition: transform 0.3s ease-in-out;
  }
`

export const LinkButton = styled.a<LinkButtonProps>`
  ${buttonStyles};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'initial')};
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:hover {
    &::before {
      transform: translateX(0);
    }
    ${icons.ArrowRight} {
      transform: translateX(5px);
    }
  }
`

export const FakeLinkButton = styled.div`
  ${buttonStyles};
`
