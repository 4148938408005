import * as React from 'react'
import styled, { css } from 'styled-components'

import * as ds from '@bob/design-system'

import { Brand } from './Brand'
import { DesktopLeftNavigation } from './Drawers/Desktop'
import { MobileDrawer } from './Drawers/Mobile'
import { MobileDrawerButton } from './Drawers/Mobile/MobileDrawerButton'
import { Overlay } from './Drawers/components/Overlay'
import { Search } from './Search'

export type AppHeaderProps = React.HTMLAttributes<HTMLDivElement>

function AppHeaderComponent({ ...props }: AppHeaderProps): React.ReactElement {
  const [mobileDrawerIsOpen, setMobileDrawerAsOpen] = React.useState(false)

  ds.hooks.useScrollLock(mobileDrawerIsOpen, { className: 'mobile-drawer-open' })
  const { onTransitionEnd, step, unmounted: mobileDrawerUnmounted } = ds.hooks.useTransition(mobileDrawerIsOpen)

  return (
    <Header
      mobileDrawerIsMounted={!mobileDrawerUnmounted}
      {...props}
      onClick={() => {
        //no-op
      }}
    >
      <HeaderBackground />
      <HeaderContainer>
        <MobileDrawerButton
          aria-controls="mobile-drawer"
          aria-expanded={mobileDrawerIsOpen}
          onClick={() => setMobileDrawerAsOpen(!mobileDrawerIsOpen)}
        />
        <Left>
          <LeftNav>
            <DesktopLeftNavigation />
          </LeftNav>
        </Left>
        <Brand />
        <Right>
          <Search />
        </Right>
      </HeaderContainer>

      {!mobileDrawerUnmounted && (
        <>
          <Overlay mobileOnly visible={step === 'during'} />
          <MobileDrawerContainer
            id="mobile-drawer"
            isOpen={mobileDrawerIsOpen}
            onTransitionEnd={onTransitionEnd}
            step={step}
          >
            <MobileDrawer onSelect={() => setMobileDrawerAsOpen(false)} />
          </MobileDrawerContainer>
        </>
      )}
    </Header>
  )
}

export const AppHeader = styled(AppHeaderComponent)``

type HeaderProps = {
  mobileDrawerIsMounted: boolean
} & React.HTMLAttributes<HTMLElement>

/**
 * The drop shadow is handled by adding sticky `before` and `after` pseudo-elements
 * to the Header. The `before` element defines the actual shadow effect,
 * while the `after` element hides it. Upon scrolling, the `after` element
 * sticks to the topmost, while the `before` element sticks slightly farther
 * from the top, unveiling the shadow.
 *
 * Kudos: https://stijndewitt.com/2018/06/12/pure-css-drop-shadow-on-scroll/
 */
export const Header = styled.header<HeaderProps>`
  background: ${ds.color.SAND_1};
  flex-shrink: 0;
  height: calc(${ds.sizes.HEADER_MOBILE_INTERNAL_HEIGHT} + ${ds.sizes.HEADER_SHADOW_HEIGHT});
  position: sticky;
  top: -${ds.sizes.HEADER_SHADOW_HEIGHT};
  z-index: ${ds.zindex.HEADER};

  // since the .lock-scroll set the body in position:fixed, we must have the
  // header also in position:fixed (sticky won't work in the context of a
  // fixed element)
  // also we have to play a bit with the height of the header because of the
  // shadows pseudo elements :before/:after (those are not needed anyway when
  // lock-scroll)

  .lock-scroll & {
    position: fixed;
    top: 0;
    width: 100%;
    height: calc(${ds.sizes.HEADER_MOBILE_INTERNAL_HEIGHT} + 2px);

    &::after {
      position: fixed;
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: sticky;
    visibility: ${({ mobileDrawerIsMounted = false }) => (mobileDrawerIsMounted ? 'hidden' : 'visible')};
  }

  &::before {
    box-shadow: 0 10px 25px ${ds.color.GRAY_2};
    height: ${ds.sizes.HEADER_SHADOW_HEIGHT};
    top: calc(${ds.sizes.HEADER_MOBILE_INTERNAL_HEIGHT} - ${ds.sizes.HEADER_SHADOW_HEIGHT} * 3);
  }

  &::after {
    background: ${ds.color.SAND_1};
    height: calc(${ds.sizes.HEADER_SHADOW_HEIGHT} + 2px);
    top: 0;
    z-index: 2;
  }

  @media screen and (${ds.breakpoint.TABLET}) {
    height: calc(${ds.sizes.HEADER_DESKTOP_INTERNAL_HEIGHT} + ${ds.sizes.HEADER_SHADOW_HEIGHT});

    .lock-scroll & {
      height: calc(${ds.sizes.HEADER_DESKTOP_INTERNAL_HEIGHT} + 2px);
    }

    &::before {
      top: calc(${ds.sizes.HEADER_DESKTOP_INTERNAL_HEIGHT} - ${ds.sizes.HEADER_SHADOW_HEIGHT} * 3);
    }
  }
`

export const HeaderBackground = styled.div`
  display: none;

  @media screen and (${ds.breakpoint.TABLET}) {
    background: ${ds.color.SAND_1};
    display: block;
    height: ${ds.sizes.HEADER_DESKTOP_HEIGHT};
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const HeaderContainer = styled(ds.styles.container.Base)`
  align-items: center;
  background: ${ds.color.SAND_1};
  display: flex;
  height: ${ds.sizes.HEADER_MOBILE_INTERNAL_HEIGHT};
  justify-content: center;
  margin-top: -${ds.sizes.HEADER_SHADOW_HEIGHT};
  padding-top: 5px;
  position: sticky;
  top: 0;
  z-index: 3;

  @media screen and (${ds.breakpoint.TABLET}) {
    height: ${ds.sizes.HEADER_DESKTOP_INTERNAL_HEIGHT};
    padding-top: 0;
  }
`

export const Left = styled.div`
  flex-grow: 1;
  width: 50%;
  display: flex;
  justify-content: flex-start;
`

export const LeftNav = styled.div`
  display: none;
  @media screen and (${ds.breakpoint.TABLET}) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -16px;
  }
`

export const Right = styled.div`
  flex-grow: 1;
  width: 50%;
  display: flex;
  justify-content: flex-end;
`

export type MobileDrawerProps = {
  isOpen: boolean
} & React.HTMLAttributes<HTMLDivElement> & { step: ds.hooks.UseTransition['step'] }

export const MobileDrawerContainer = styled.div<MobileDrawerProps>`
  background: ${ds.color.SAND_1};
  overflow-x: hidden;
  overflow-y: auto;
  transition-duration: 600ms;
  transition-property: transform, visibility;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};

  .lock-scroll & {
    overflow: hidden;
  }

  height: calc(100vh - ${ds.sizes.HEADER_MOBILE_INTERNAL_HEIGHT} + ${ds.sizes.HEADER_SHADOW_HEIGHT});

  ${({ step }) => {
    if (step === 'before') {
      return css`
        transform: translate3d(-100%, 0, 0);
      `
    }
    if (step === 'during') {
      return css`
        transform: translate3d(0);
      `
    }
    if (step === 'after') {
      return css`
        transform: translate3d(-100%, 0, 0);
      `
    }
  }} @media screen and (${ds.breakpoint.TABLET}) {
    display: none;
  }
`
