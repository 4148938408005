// check if at least one @ is present.
// check that there is at least 1 char before last @
// check that there is at least 1 char after last @
export function basicEmailValidation(email: string): boolean {
  const lastAtIndex = email.lastIndexOf('@')
  if (lastAtIndex !== -1) {
    const before = email.slice(0, lastAtIndex)
    const after = email.slice(lastAtIndex + 1)
    return before.length > 0 && after.length > 0
  }
  return false
}

export function basicNumberValidation(value: string): boolean {
  return /^[0-9]+$/.test(String(value))
}
