import styled, { css } from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as colors from '../constants/colors'

export type WrapperColor = 'blue' | 'green' | 'orange' | 'pink' | 'yellow'
export type WrapperProps = {
  color?: WrapperColor
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 22px;
  text-align: center;

  @media screen and (${breakpoint.TABLET}) {
    padding: 11px 0px;
    text-align: initial;
  }

  ${({ color = 'pink' }) => {
    if (color === 'blue')
      return css`
        background-color: ${colors.BLUE};
      `
    if (color === 'green')
      return css`
        background-color: ${colors.LIGHT_GREEN};
      `
    if (color === 'orange')
      return css`
        background-color: ${colors.ORANGE};
      `
    if (color === 'pink')
      return css`
        background-color: ${colors.PINK};
      `
    if (color === 'yellow')
      return css`
        background-color: ${colors.YELLOW};
      `
  }}
`
