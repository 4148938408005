import * as taxonomy from './src/taxonomies.ts'
import * as brand from './src/brands.ts'

import brandData from './brands.json'
import taxonomyData from './taxonomy.json'
import miscData from './misc.json'
import { makeHierarchy, Hierarchy } from './hierarchy.ts'

const { tags, taxonomies, types } = taxonomy.denormalize(taxonomyData)
const { brands, brandCategories } = brand.denormalize(brandData, { tags })

export { tags, taxonomies, types, brands, brandCategories }

export const locales = miscData.locales
export const defaultLocale = miscData.defaultLocale
export const aboutPages = miscData.aboutPages
export const notification = {
  bienoubien: miscData.notifications.find((notification) => notification.target === 'bienoubien'),
  boutique: miscData.notifications.find((notification) => notification.target === 'boutique'),
}

export const hierarchy = makeHierarchy({ taxonomies, types })

export type Cold = {
  tags: typeof tags
  taxonomies: typeof taxonomies
  types: typeof types
  brands: typeof brands
  brandCategories: typeof brandCategories
  locales: typeof locales
  defaultLocale: typeof defaultLocale
  aboutPages: typeof aboutPages
  notification: typeof notification
  hierarchy: Hierarchy
}

export * from './src/utils'
