import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

export type ContainerProps = React.HTMLAttributes<HTMLDivElement>

function ContainerComponent({ ...baseContainerProps }: ContainerProps): React.ReactElement {
  return <S.container.Base {...baseContainerProps} />
}

export const Container = styled(ContainerComponent)``
