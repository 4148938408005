import * as React from 'react'

import type { Toast, ToastManager } from './context'

enum ActionTypes {
  'DISPATCH',
  'DISMISS',
}
type DispatchAction = {
  type: ActionTypes.DISPATCH
  toast: Toast
}
type DismissAction = {
  type: ActionTypes.DISMISS
  id: number
}
type Action = DispatchAction | DismissAction

export function useToastManager(): ToastManager {
  const [toasts, dispatcher] = React.useReducer(reducer, [])

  return { toasts: toasts, dispatch, dismiss }

  function dispatch(toast: Toast) {
    return dispatcher({ type: ActionTypes.DISPATCH, toast })
  }

  function dismiss(id: number) {
    return dispatcher({ type: ActionTypes.DISMISS, id })
  }
}

function reducer(state: Toast[], action: Action) {
  switch (action.type) {
    case ActionTypes.DISPATCH:
      return [...state, action.toast]
    case ActionTypes.DISMISS:
      return [...state.filter((toast) => toast.id !== action.id)]
    default:
      return state
  }
}
