type Param = string | string[] | undefined

export function priceRange(param: Param): { start?: number; end?: number } | undefined {
  if (Array.isArray(param) || param === undefined) {
    return undefined
  }

  const [startString, endString] = param.split(':')
  const start = startString === '' ? undefined : Number(startString)
  const end = endString === '' ? undefined : Number(endString)

  return {
    start: Number.isNaN(start) ? undefined : start,
    end: Number.isNaN(end) ? undefined : end,
  }
}

export function number(param: Param): number | undefined {
  const parsed = Number(string(param))
  if (Number.isNaN(parsed)) {
    return undefined
  }
  return parsed
}

export function boolean(param: Param): boolean | undefined {
  if (param === 'true') {
    return true
  }
  if (param === 'false') {
    return false
  }
  return undefined
}

export function string(param: Param): string | undefined {
  if (Array.isArray(param)) {
    return undefined
  }
  return param
}

export function array(param: Param): string[] | undefined {
  if (typeof param === 'string') {
    return param.split(',')
  }
  return param
}
