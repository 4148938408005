/* eslint-disable react/display-name */

import * as React from 'react'

import * as richtext from '@owl-nest/rich-text'
import * as contentful from '@owl-nest/contentful'
import * as ds from '@bob/design-system'

import { Hyperlink as BaseHyperlink } from './Hyperlink'

export type RichTextProps<ENTRY extends { sys: { id: string } }> = richtext.RichTextProps<ENTRY>

export function RichText<ENTRY extends { sys: { id: string } }>({
  document,
  links,
  renderEntry,
  renderMark: {
    Bold = ds.styles.wysiwyg.Bold,
    Italic = ds.styles.wysiwyg.Italic,
    Underline = ds.styles.wysiwyg.Underline,
  } = {},
  renderNode: {
    Paragraph = ds.styles.wysiwyg.Paragraph,
    Title1 = ds.styles.wysiwyg.Title,
    UnorderedList = ds.styles.wysiwyg.UnorderedList,
    OrderedList = ds.styles.wysiwyg.OrderedList,
    ListItem = ds.styles.wysiwyg.ListItem,
    BlockQuote = ds.styles.wysiwyg.BlockQuote,
    Hyperlink = BaseHyperlink,
    EmbeddedAsset = ({ asset }: { asset: contentful.Asset }) => (
      <ds.styles.wysiwyg.Figure>
        <ds.styles.wysiwyg.Image src={asset.url} alt={asset.title} />
        {asset.description && (
          <ds.styles.wysiwyg.Caption
            dangerouslySetInnerHTML={{
              __html: asset.description,
            }}
          />
        )}
      </ds.styles.wysiwyg.Figure>
    ),
  } = {},
}: RichTextProps<ENTRY>): React.ReactElement<RichTextProps<ENTRY>> {
  return (
    <richtext.RichText
      document={document}
      links={links}
      renderEntry={renderEntry}
      renderMark={{ Bold, Italic, Underline }}
      renderNode={{
        Paragraph,
        Title1,
        Title2: Title1,
        Title3: Title1,
        Title4: Title1,
        Title5: Title1,
        Title6: Title1,
        UnorderedList,
        OrderedList,
        ListItem,
        BlockQuote,
        Hyperlink,
        EmbeddedAsset,
      }}
    />
  )
}
