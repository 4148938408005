import { renderToStaticMarkup } from 'react-dom/server'
import * as React from 'react'

export function toDataUrl<P extends {}>(
  component: React.FunctionComponent<P> | React.ComponentClass<P> | keyof React.ReactSVG,
  props: P,
): string {
  try {
    return `data:image/svg+xml; utf8, ${renderToStaticMarkup(React.createElement(component, props)).replace(
      /#/g,
      '%23',
    )}`
  } catch (e) {
    console.log(component, props)
    console.log(e)
    return ''
  }
}
