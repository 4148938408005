import * as React from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import * as ds from '@bob/design-system'
import * as url from '@bob/url'

function NavigationMenuComponent(): React.ReactElement {
  return (
    <>
      <Link prefetch={false} href={url.brands().path} passHref legacyBehavior>
        <DrawerLink>Guide</DrawerLink>
      </Link>
      <Link prefetch={false} href={url.manifesto().path} passHref legacyBehavior>
        <DrawerLink>Charte</DrawerLink>
      </Link>
      <Link prefetch={false} href={url.articles().path} passHref legacyBehavior>
        <DrawerLink>Magazine</DrawerLink>
      </Link>
      <Link prefetch={false} href={url.newsletter().path} passHref legacyBehavior>
        <DrawerLink>Newsletter</DrawerLink>
      </Link>
    </>
  )
}

export const DesktopLeftNavigation = styled(NavigationMenuComponent)``

export const drawerLinkStyles = css`
  font-family: ${ds.font.PRIMARY};
  font-size: 16px;
  line-height: 1;
  padding: 10px;
  text-align: center;

  @media screen and (${ds.breakpoint.LAPTOP}) {
    font-size: 18px;
    padding: 10px 16px;
  }
`

export const DrawerLink = styled(ds.LinkButton)`
  ${drawerLinkStyles};
  display: inline-flex;
  text-decoration: none;

  ${ds.icons.Icon} {
    display: none;
  }

  &:hover {
    font-weight: bolder;
  }
`
