import * as React from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import * as ds from '@bob/design-system'
import * as url from '@bob/url'

type MobileDrawerProps = {
  onSelect: () => void
}

export function MobileDrawer({
  onSelect = () => {
    // no-op
  },
}: MobileDrawerProps): React.ReactElement<MobileDrawerProps> {
  const router = useRouter()

  const [searchValue, setSearchValue] = React.useState('')

  return (
    <>
      <Section>
        <ds.styles.heading.Title3>Rechercher</ds.styles.heading.Title3>
        <ds.SearchInput
          onChange={(event) => {
            setSearchValue(event.target.value)
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              let targetUrl = ''

              if (searchValue === '') {
                targetUrl = '/fr/bienoubien/brands'
              } else {
                targetUrl = `/fr/bienoubien/brands?searchableTag=${searchValue}`
              }

              router.push(targetUrl, undefined, { shallow: true })

              event.currentTarget.blur()
              onSelect()
            }
          }}
          placeholder="Rechercher une marque"
        />
      </Section>
      <Section>
        <ds.LinkButton href={url.brands().path} onClick={onSelect}>
          Guide
        </ds.LinkButton>
        <ds.LinkButton href={url.manifesto().path} onClick={onSelect}>
          Charte
        </ds.LinkButton>
        <ds.LinkButton href={url.articles().path} onClick={onSelect}>
          Magazine
        </ds.LinkButton>
        <ds.LinkButton href={url.newsletter().path} onClick={onSelect}>
          Newsletter
        </ds.LinkButton>
      </Section>
      <SillyFiller />
    </>
  )
}

const Section = styled.section`
  padding: 20px;

  ${ds.styles.heading.Title3} {
    margin-bottom: 20px;
  }

  ${ds.LinkButton} {
    display: flex;

    + ${ds.LinkButton} {
      margin-top: 35px;
    }
  }
`

/**
 * HACK: This is needed because the drawer's height is based on `vh`. Problem:
 *   `vh` on mobile browsers take UI elements into account (address bar and stuff)*.
 *   As a side effect, the last elements of the drawer are either hidden
 *   when those UI elements are on the bottom of the display, or need extra scrolling
 *   when the UI elements are on top.
 *
 *   This filler is here to balance that, and allow display of all elements for most
 *   mobile browsers.
 *
 *   *https://bugs.webkit.org/show_bug.cgi?id=141832#c5
 */
const SillyFiller = styled.section`
  background-color: ${ds.color.SAND_1};
  width: 100%;
`
