import * as api from '@owl-nest/api-client/latest'
import * as logger from '@owl-nest/logger'
import * as utils from '@owl-nest/utils'

export async function register(
  email: string,
  list: string,
): Promise<{ success: true } | { success: false; message: string }> {
  try {
    const isValid = utils.validation.basicEmailValidation(email)

    if (!isValid) {
      return {
        success: false,
        message: "L'email entré est invalide",
      }
    }

    const response = await api.request(`/api/subscribe/${list}`, {
      method: 'POST',
      type: 'json',
      body: {
        email,
      },
    })

    return response.caseOf<{ success: true } | { success: false; message: string }>({
      left: (failure) => {
        switch (failure.status) {
          case 422:
            return { success: false, message: "L'email entré est invalide" }
          default:
            return { success: false, message: 'Une erreur est survenue' }
        }
      },
      right: () => {
        return { success: true }
      },
    })
  } catch (exception) {
    logger.err(`[newsletter] Subscription failed`, {
      exception,
      list,
    })

    return { success: false, message: 'Une erreur est survenue' }
  }
}
