import * as contentful from '@boutique/contentful'
import type * as entity from '@boutique/entities'
import { Tag, Taxonomy, ExtraManifesto, ExtraMoment, ExtraPage, ExtraPillar, ExtraCategory } from './types.ts'
import { paginate } from './utils.ts'

const REDACTED_TAGS = ['je-me-cultive', 'je-moccupe-des-animaux']

type Types = Record<
  Exclude<contentful.taxonomy.TaxonomyType, 'sub-category-1' | 'category' | 'sub-category-2' | 'product-type'>,
  string
> &
  Record<'sub-category-1' | 'category' | 'sub-category-2' | 'product-type', string[]>

type SlimDenormalized = {
  tags: Record<string, Omit<entity.tag.ColdTag, 'page' | 'manifesto'>>
  taxonomies: Record<string, entity.taxonomy.ColdTaxonomy>
  types: Types
}

export function slimDenormalize(collector: SlimCollector): SlimDenormalized {
  const denormalized: SlimDenormalized = { tags: {}, taxonomies: {}, types: collector.types }

  for (const tag of Object.values(collector.tags)) {
    denormalized.tags[tag.slug] = {
      name: tag.name,
      slug: tag.slug,
      parents: [],
    }
  }

  for (const taxonomy of Object.values(collector.taxonomies)) {
    denormalized.taxonomies[taxonomy.slug] = {
      children: { taxonomies: [], tags: taxonomy.tags },
      name: taxonomy.name,
      slug: taxonomy.slug,
      type: taxonomy.type ?? null,
      order: taxonomy.order ?? null,
    }

    for (const tag of taxonomy.tags) {
      denormalized.tags[tag].parents.push(taxonomy.slug)
    }
  }

  for (const taxonomy of Object.values(collector.taxonomies)) {
    if (taxonomy.parent && taxonomy.parent in denormalized.taxonomies) {
      denormalized.taxonomies[taxonomy.parent].children.taxonomies.push(taxonomy.slug)
    }
  }

  for (const extra of collector.extras) {
    if (REDACTED_TAGS.includes(extra.tag)) {
      continue
    }
    const tag = denormalized.tags[extra.tag]
    switch (extra.type) {
      case 'moment': {
        tag.moment = extra
        break
      }
      case 'pillar': {
        tag.pillar = extra
        break
      }
      case 'category': {
        tag.category = extra
        break
      }
    }
  }

  return denormalized
}

type Denormalized = {
  tags: Record<string, entity.tag.ColdTag>
  taxonomies: Record<string, entity.taxonomy.ColdTaxonomy>
  types: Types
}

export function denormalize(collector: Collector): Denormalized {
  const denormalized: Denormalized = { tags: {}, taxonomies: {}, types: collector.types }

  for (const tag of Object.values(collector.tags)) {
    denormalized.tags[tag.slug] = {
      name: tag.name,
      slug: tag.slug,
      parents: [],
    }
  }

  for (const taxonomy of Object.values(collector.taxonomies)) {
    denormalized.taxonomies[taxonomy.slug] = {
      children: { taxonomies: [], tags: taxonomy.tags },
      name: taxonomy.name,
      slug: taxonomy.slug,
      type: taxonomy.type ?? null,
      order: taxonomy.order ?? null,
    }
    for (const tag of taxonomy.tags) {
      denormalized.tags[tag].parents.push(taxonomy.slug)
    }
  }

  for (const taxonomy of Object.values(collector.taxonomies)) {
    if (taxonomy.parent) {
      denormalized.taxonomies[taxonomy.parent].children.taxonomies.push(taxonomy.slug)
    }
  }

  for (const extra of collector.extras) {
    if (REDACTED_TAGS.includes(extra.tag)) {
      continue
    }
    const tag = denormalized.tags[extra.tag]
    switch (extra.type) {
      case 'manifesto': {
        tag.manifesto = extra
        break
      }
      case 'moment': {
        tag.moment = extra
        break
      }
      case 'page': {
        tag.page = extra
        break
      }
      case 'pillar': {
        tag.pillar = extra
        break
      }
      case 'category': {
        tag.category = extra
        break
      }
    }
  }

  return denormalized
}

export type SlimCollector = {
  tags: Record<string, Tag>
  taxonomies: Record<string, Taxonomy>
  types: Types
  extras: (ExtraMoment | ExtraPillar | ExtraCategory)[]
}

export function slim(collector: Collector): SlimCollector {
  const taxonomiesWithType = Object.fromEntries(
    Object.entries(collector.taxonomies).filter(([slug, taxonomy]) => {
      return taxonomy.type !== undefined
    }),
  )

  return {
    ...collector,
    taxonomies: taxonomiesWithType,
    extras: collector.extras.filter(
      (extra): extra is ExtraMoment | ExtraPillar | ExtraCategory =>
        extra.type === 'moment' || extra.type === 'pillar' || extra.type === 'category',
    ),
  }
}

export type Collector = {
  tags: Record<string, Tag>
  taxonomies: Record<string, Taxonomy>
  types: Types
  extras: (ExtraManifesto | ExtraMoment | ExtraPage | ExtraPillar | ExtraCategory)[]
}

export async function collect(locale: string): Promise<Collector> {
  const collector: Omit<Collector, 'types'> & { types: Partial<Collector['types']> } = {
    taxonomies: {},
    tags: {},
    extras: [],
    types: {},
  }

  const [tags, taxonomies, extraPages, extraPillars, extraMoments, extraManifestos, extraCategories] =
    await Promise.all([
      paginate({
        getPage: contentful.tag.getColdCollection,
        items: (page) => page.tagCollection.items,
        total: (page) => page.tagCollection.total,
        locale,
        batch: 50,
      }),
      paginate({
        getPage: contentful.taxonomy.getColdCollection,
        items: (page) => page.taxonomyCollection.items,
        total: (page) => page.taxonomyCollection.total,
        locale,
        batch: 50,
      }),
      paginate({
        getPage: contentful.tagExtra.getExtraPageCollection,
        items: (page) => page.tagExtraPageCollection.items,
        total: (page) => page.tagExtraPageCollection.total,
        locale,
        batch: 10,
      }),
      paginate({
        getPage: contentful.tagExtra.getExtraPillarCollection,
        items: (page) => page.tagExtraPilarCollection.items,
        total: (page) => page.tagExtraPilarCollection.total,
        locale,
        batch: 50,
      }),
      paginate({
        getPage: contentful.tagExtra.getExtraMomentCollection,
        items: (page) => page.tagExtraMomentCollection.items,
        total: (page) => page.tagExtraMomentCollection.total,
        locale,
        batch: 50,
      }),
      paginate({
        getPage: contentful.tagExtra.getExtraManifestoCollection,
        items: (page) => page.tagExtraManifestoCollection.items,
        total: (page) => page.tagExtraManifestoCollection.total,
        locale,
        batch: 50,
      }),
      paginate({
        getPage: contentful.tagExtra.getExtraCategoryCollection,
        items: (page) => page.tagExtraCategoryCollection.items,
        total: (page) => page.tagExtraCategoryCollection.total,
        locale,
        batch: 50,
      }),
    ])

  for (const taxonomy of taxonomies) {
    collector.taxonomies[taxonomy.slug] = {
      name: taxonomy.name,
      slug: taxonomy.slug,
      type: taxonomy.type ?? undefined,
      parent: taxonomy.parent?.slug,
      order: taxonomy.order ?? undefined,
      tags: taxonomy.tagsCollection.items.filter((item) => !REDACTED_TAGS.includes(item.slug)).map((item) => item.slug),
    }
  }

  for (const tag of tags) {
    if (REDACTED_TAGS.includes(tag.slug)) {
      continue
    }
    collector.tags[tag.slug] = {
      name: tag.name,
      slug: tag.slug,
    }
  }

  for (const extra of extraPages) {
    collector.extras.push({
      tag: extra.tag.slug,
      type: 'page',
      description: extra.description,
      descriptionShort: extra.descriptionShort,
      imageCover: extra.imageCover,
    })
  }
  for (const extra of extraPillars) {
    collector.extras.push({
      tag: extra.tag.slug,
      type: 'pillar',
      altName: extra.altName,
      order: extra.order,
      description: extra.description,
      icon: extra.icon,
    })
  }
  for (const extra of extraMoments) {
    collector.extras.push({
      tag: extra.tag.slug,
      type: 'moment',
      order: extra.order,
      icon: extra.icon,
      timeEnd: extra.timeEnd,
      timeStart: extra.timeStart,
    })
  }
  for (const extra of extraManifestos) {
    collector.extras.push({ tag: extra.tag.slug, type: 'manifesto', imageContent: extra.imageContent })
  }
  for (const extra of extraCategories) {
    collector.extras.push({
      tag: extra.tag.slug,
      type: 'category',
      descriptionShort: extra.descriptionShort,
      altName: extra.altName,
    })
  }

  for (const taxonomy of Object.values(taxonomies)) {
    switch (taxonomy.type) {
      case 'environmental-pillar':
      case 'social-pillar':
      case 'color':
      case 'gender':
      case 'moment':
      case 'size': {
        collector.types[taxonomy.type] = taxonomy.slug
        break
      }
      case 'category':
      case 'product-type':
      case 'sub-category-1':
      case 'sub-category-2': {
        collector.types[taxonomy.type] = collector.types[taxonomy.type] || []
        collector.types[taxonomy.type]?.push(taxonomy.slug)
        break
      }
    }
  }

  // asserts that each taxonomy types exists
  if (collector.types.category === undefined) {
    throw Error('no category taxonomy found')
  }
  if (collector.types.color === undefined) {
    throw Error('no color taxonomy found')
  }
  if (collector.types['environmental-pillar'] === undefined) {
    throw Error('no environmental-pillar taxonomy found')
  }
  if (collector.types['social-pillar'] === undefined) {
    throw Error('no social-pillar taxonomy found')
  }
  if (collector.types.gender === undefined) {
    throw Error('no gender taxonomy found')
  }
  if (collector.types['product-type'] === undefined) {
    throw Error('no product-type taxonomy found')
  }
  if (collector.types.size === undefined) {
    throw Error('no size taxonomy found')
  }
  if (collector.types['sub-category-1'] === undefined) {
    throw Error('no sub-category-1 taxonomy found')
  }
  if (collector.types['sub-category-2'] === undefined) {
    throw Error('no sub-category-2 taxonomy found')
  }

  // the "types" property of `collector` that was marked as partial is in fact
  // not because of the series of previous assertions. The result can be cast to
  // `Collector`, but typescript is not happy. Any it is then.
  return collector as any
}
