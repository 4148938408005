import * as React from 'react'
import styled from 'styled-components'

import * as ds from '@bob/design-system'

export type ButtonNavProps = React.ButtonHTMLAttributes<HTMLButtonElement>

function MobileDrawerButtonComponent({ ...rest }: ButtonNavProps): React.ReactElement {
  return (
    <button {...rest}>
      <svg width="28" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke="currentColor" strokeWidth="2" d="M0 1h28M0 11h20M0 21h28" />
      </svg>
    </button>
  )
}

export const MobileDrawerButton = styled(MobileDrawerButtonComponent)`
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  height: ${ds.sizes.HEADER_MOBILE_INTERNAL_HEIGHT};
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 68px;

  &:focus {
    outline: none;
  }

  @media screen and (${ds.breakpoint.TABLET}) {
    display: none;
  }
`
