import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'
import { Loader } from './Loader'

export type ButtonProps = Omit<S.button.ButtonProps, '$loading'> &
  React.ButtonHTMLAttributes<HTMLElement> & {
    loading?: S.button.ButtonProps['$loading']
    iconLeft?: React.ReactNode
    iconRight?: React.ReactNode
  }

function ButtonComponent({ loading, children, iconLeft, iconRight, ...rest }: ButtonProps): React.ReactElement {
  return (
    <S.button.ButtonBase $loading={loading} {...rest}>
      {loading && <Loader />}
      {iconLeft}
      <S.button.Text>{children}</S.button.Text>
      {iconRight}
    </S.button.ButtonBase>
  )
}

export const Button = styled(ButtonComponent)`
  ${Loader} {
    position: absolute;
  }
`

type ButtonSkeletonProps = {
  autoSized?: boolean
  className?: string
  width?: number
}

function ButtonSkeletonComponent({ autoSized = false, className, width = 200 }: ButtonSkeletonProps) {
  return (
    <ButtonSkeletonWrapper autoSized={autoSized} className={className} width={width}>
      <S.skeleton.Rectangle className={className} height={50} />
    </ButtonSkeletonWrapper>
  )
}

export const ButtonSkeleton = styled(ButtonSkeletonComponent)<ButtonSkeletonProps>``

const ButtonSkeletonWrapper = styled.div<{ autoSized: boolean; width: number }>`
  max-width: ${({ autoSized, width }) => (autoSized ? '100%' : `${width}px`)};
`
