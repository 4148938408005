import styled from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import { MAIN_DESKTOP_CONTAINER_WIDTH, MAIN_MOBILE_CONTAINER_WIDTH } from '../constants/sizes'

export const Base = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${MAIN_MOBILE_CONTAINER_WIDTH};
  min-width: 320px;

  @media screen and (${breakpoint.MOBILE_L}) {
    max-width: ${MAIN_DESKTOP_CONTAINER_WIDTH};
    padding-left: 40px;
    padding-right: 40px;
  }
`
