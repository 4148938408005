import * as React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import * as ds from '@bob/design-system'

export type BobXmasGameModalProps = {
  onClose: () => void
}

export function BobXmasGameModal({ onClose }: BobXmasGameModalProps): React.ReactElement {
  const [isOpen, setIsOpen] = React.useState(true)

  return (
    <Modal
      closable
      onClose={() => {
        onClose()
        setIsOpen(false)
      }}
      open={isOpen}
    >
      <ds.styles.heading.Title3>
        <Brush>Grand Tirage au sort de Noël</Brush>
      </ds.styles.heading.Title3>
      <ParagraphWrapper>
        <Text>
          Bien&nbsp;ou&nbsp;Bien et Ulule&nbsp;Boutique s&apos;unissent pour célébrer un Noël engagé en organisant un
          grand tirage au sort*&nbsp;!
          <br />
          <br />
          A la clé&nbsp;? Une carte cadeau de 400&nbsp;€ pour gâter vos proches ou vous faire plaisir sur
          Ulule&nbsp;Boutique et d&apos;autres surprises&nbsp;!
          <br />
          <br />
          <i>
            *Jeu sans obligation d’achat. Participations du 8/11/23 au 11/12/23. Les gagnants seront tirés au sort le
            12/12/23 et contactés par mail le jour même.
          </i>
        </Text>
        <ds.Picture src={process.env.ASSET_PREFIX + '/assets/rasterized/modal/bob_Tirage_au_sort.png'} />
      </ParagraphWrapper>
      <Link
        target="_blank"
        href="https://noel.boutique.ulule.com/?utm_source=bob&utm_medium=display&utm_campaign=tirage-au-sort"
      >
        <ds.Button
          onClick={() => {
            onClose()
            setIsOpen(false)
          }}
        >
          Je tente ma chance
        </ds.Button>
      </Link>
    </Modal>
  )
}

const ParagraphWrapper = styled.div`
  align-items: center;
  max-width: 650px;
  margin-bottom: 50px;

  display: flex;
  flex-direction: column;

  ${ds.Picture} {
    width: 100%;
    order: -1;
    width: 50%;
    height: 50%;
    margin-bottom: 30px;
  }

  @media screen and (${ds.breakpoint.TABLET}) {
    flex-direction: row;

    ${ds.Picture} {
      flex-shrink: 0.6;
      margin-top: 0;
      order: 0;
      padding-left: 5px;
    }
  }
`

const Text = styled(ds.styles.copy.Base)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Modal = styled(ds.Modal)`
  ${ds.styles.heading.Title3} {
    margin-bottom: 15px;
    text-align: center;
    line-height: 120%;

    @media screen and (${ds.breakpoint.TABLET}) {
      margin-bottom: 35px;
    }
  }

  ${ds.styles.modal.ModalBody} {
    position: relative;
    padding-top: 10px;
    height: 80vh;
    max-height: 720px;

    @media screen and (${ds.breakpoint.TABLET}) {
      height: 100%;
    }
  }

  ${ds.styles.modal.ModalWrapper} {
    height: auto;
    margin: 0;

    @media screen and (${ds.breakpoint.TABLET}) {
      min-width: 90vw;
    }

    @media screen and (${ds.breakpoint.LAPTOP}) {
      min-width: 900px;
      width: 900px;
    }
  }

  ${ds.styles.modal.ModalScrollableContainer} {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
  }
`

const BaseBrush = styled.span`
  background-position: center 10px;
  background-repeat: no-repeat;
  display: inline-block;
  margin: -0.5em;
  padding: 0.5em;
`

const BrushDataUrl = ds.utils.svg.toDataUrl(ds.brush.full.FullBrush2, {
  width: 480,
  height: 77,
  fill: ds.color.BLUE,
})

const Brush = styled(BaseBrush)`
  background-image: url('${BrushDataUrl}');
`
