import * as React from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import { aboutPages } from '@owl-nest/cold-data/client'
import * as ds from '@bob/design-system'
import { NewsletterForm } from '@bob/newsletter-form'
import * as url from '@bob/url'

import { NavLink, LinkStyled } from './NavLink'

const sortedAboutPages = aboutPages.sort((a, b) => a.order - b.order)

function AppFooterComponent({ ...rest }): React.ReactElement {
  return (
    <Footer {...rest}>
      <ds.Container>
        <Grid>
          <Col1>
            <Link prefetch={false} href={url.homepage().path} passHref legacyBehavior>
              <BrandLink>
                <ds.Logo themeLight width={180} height={109} />
              </BrandLink>
            </Link>
            <SocialList>
              <li>
                <SocialLink
                  href="https://twitter.com/bienoubien_com"
                  rel="noopener external"
                  target="_blank"
                  title="Notre page Twitter"
                >
                  <ds.icons.Twitter />
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  href="https://www.instagram.com/bienoubien_com"
                  rel="noopener external"
                  target="_blank"
                  title="Notre page Instagram"
                >
                  <ds.icons.Instagram />
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  href="https://www.facebook.com/bienoubiencom"
                  rel="noopener external"
                  target="_blank"
                  title="Notre page Facebook"
                >
                  <ds.icons.Facebook />
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  href="https://www.linkedin.com/company/bienoubien/"
                  rel="noopener external"
                  target="_blank"
                  title="Notre page LinkedIn"
                >
                  <ds.icons.LinkedIn />
                </SocialLink>
              </li>
            </SocialList>
          </Col1>
          <Col2>
            <Section>
              <Link prefetch={false} href="http://bit.ly/rejoindre-guide-bienoubien" passHref legacyBehavior>
                <VendorLink>Rejoindre le guide Bien ou Bien</VendorLink>
              </Link>
              <Link prefetch={false} href="https://boutique.ulule.com" passHref legacyBehavior>
                <VendorLink>
                  Retrouver la sélection Bien ou Bien
                  <br />sur Ulule Boutique
                </VendorLink>
              </Link>
            </Section>
            <Section>
              <Title>Inscrivez-vous à notre newsletter</Title>
              <p>Pour recevoir les actus dans votre boîte mail</p>
              <NewsletterForm buttonOutlined isWhiteButton />
            </Section>
          </Col2>
          <Col3>
            <Nav>
              <NavLink href={url.newsletter().path}>Newsletter</NavLink>
              {/* TODO : remove filter function once the new bob is online */}
              {sortedAboutPages
                .filter((aboutPage) => [1, 30, 40, 100].includes(aboutPage.order))
                .map((aboutPage) =>
                  aboutPage.slug.startsWith('http') ? (
                    <LinkStyled href={aboutPage.slug} key={aboutPage.slug} rel="noopener noreferrer" target="_blank">
                      {aboutPage.title}
                    </LinkStyled>
                  ) : (
                    <NavLink
                      key={aboutPage.slug}
                      href={
                        url.aboutPage({
                          slug: aboutPage.slug,
                          title: aboutPage.title,
                        }).path
                      }
                    >
                      {aboutPage.title}
                    </NavLink>
                  ),
                )}{' '}
              <NavLink href="https://fr.ulule.com/">Crowdfunding</NavLink>
            </Nav>

            <Contact>
              <Title>Contactez nous</Title>
              <ul>
                <li>
                  <a href="mailto:contact@bienoubien.com">contact@bienoubien.com</a>
                </li>
                {/* <li>01 23 45 67 89</li> */}
              </ul>
            </Contact>
          </Col3>
        </Grid>
      </ds.Container>
    </Footer>
  )
}

export const AppFooter = styled(AppFooterComponent)``

export const Footer = styled.footer`
  z-index: ${ds.zindex.FOOTER};
  flex-shrink: 0;
  padding: 50px 0;
  background-color: ${ds.color.GRAY_3};
  color: ${ds.color.WHITE};
  font-size: 14px;
`

export const Grid = styled.div`
  display: grid;
  @media not screen and (${ds.breakpoint.TABLET}) {
    row-gap: 50px;
    grid-template-areas: 'col-1' 'col-2' 'col-3';
  }
  @media screen and (${ds.breakpoint.TABLET}) {
    align-items: center;
    row-gap: 50px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'col-1 col-1' 'col-2 col-3';
  }
  @media screen and (${ds.breakpoint.LAPTOP}) {
    align-items: center;
    grid-template-areas: 'col-1 col-2 col-3';
    grid-template-columns: repeat(3, 1fr);
  }
`

const spacingX = css`
  @media screen and (${ds.breakpoint.TABLET}) {
    padding-left: calc(10% + 20px);
    padding-right: calc(10% + 20px);
  }
`

export const Col1 = styled.div`
  text-align: center;
  grid-area: col-1;
  ${spacingX};
`

export const Col2 = styled.div`
  grid-area: col-2;
  ${spacingX};

  ${ds.LinkButton}:first-of-type {
    font-size: 16px;
  }

  @media not screen and (${ds.breakpoint.TABLET}) {
    border-bottom: 1px solid ${ds.color.WHITE};
    padding-bottom: 50px;
  }
  @media screen and (${ds.breakpoint.TABLET}) {
    border-right: 1px solid ${ds.color.WHITE};
  }
`

export const Col3 = styled.div`
  grid-area: col-3;
  ${spacingX};
  @media not screen and (${ds.breakpoint.TABLET}) {
    text-align: center;
  }
`

export const BrandLink = styled.a`
  display: inline-block;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.75;
  }

  ${ds.Logo} {
    width: initial;
    height: initial;
  }
`

export const SocialList = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  > * + * {
    margin-left: 10px;
  }
`

export const SocialLink = styled.a`
  display: block;
  padding: 10px;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
`

export const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  ${NewsletterForm} {
    display: grid;
    gap: 20px;
    margin-top: 20px;
  }
`

export const Title = styled.div`
  font-size: 22px;
  font-weight: ${ds.font.WEIGHT.SEMI_BOLD};

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const Nav = styled.ul`
  display: grid;
  gap: 15px;
`

const VendorLink = styled(ds.LinkButton)`
  color: ${ds.color.WHITE};
  margin-bottom: 10px;
  justify-content: space-between;
  width: 80%;

  @media screen and (${ds.breakpoint.TABLET}) {
    width: 92%;
  }
`

const Contact = styled.div`
  margin-top: 30px;
`
